import React, { ErrorInfo, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: Boolean;
}

//The simplisity of this component is intended, should use the smallest amount of components and logic as possible
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //Error logging service
  }

  render() {
    if (this.state.hasError) {
      return (
        <section>
          <h1>Something went wrong</h1>
          <p>Please contact the support and describe what you tried to do</p>
        </section>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
