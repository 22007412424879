export enum ACTION {
  MESSAGE_ERROR = "DIALOG_ERROR",
  MESSAGE_INFO = "DIALOG_MESSAGE",
  RESET_MESSAGE = "DIALOG_RESET_MESSAGE",
}

export enum MESSAGE_TYPE {
  ERROR = 1,
  MESSAGE = 2,
}
