import ReducerType from "../ReducerType";
import { MESSAGE_TYPE, ACTION } from "./dialogVariables";

type Action = {
  type: ACTION;
  message: string | undefined;
  error: object | string | undefined;
};

const initialState = {
  message: {},
};

const dialogReducer: ReducerType = (state = initialState, action: Action) => {
  switch (action.type) {
    case ACTION.MESSAGE_ERROR:
      return Object.assign({}, state, {
        message: {
          value: action.message,
          error: action.error,
          type: MESSAGE_TYPE.ERROR,
        },
      });
    case ACTION.MESSAGE_INFO:
      return Object.assign({}, state, {
        message: {
          value: action.message,
          error: action.error,
          type: MESSAGE_TYPE.MESSAGE,
        },
      });
    case ACTION.RESET_MESSAGE: {
      return Object.assign({}, state, {
        message: {},
      });
    }
    default:
      return state;
  }
};

export default dialogReducer;
