export default class URL {
  #baseUrl: string;
  #parameters: URLSearchParams;
  constructor(baseUrl: string) {
    this.#baseUrl = baseUrl;
    this.#parameters = new URLSearchParams();
  }

  addParameter(id: string, value: string) {
    this.#parameters.append(id, value);
  }

  get toString(): string {
    const query = this.#parameters.toString();
    return this.#baseUrl + (query.length > 0 ? "?" + query : "");
  }
}
