import { combineReducers } from "redux";
import defaultSessionReducer from "./session/defaultSessionReducer";
import dialogReducer from "./dialog/dialogReducer";
import ReducerType from "./ReducerType";

type IReducers = {
  [key: string]: ReducerType;
};

function createRecuder(overwritingReducers: IReducers) {
  const defaultReducers: IReducers = {
    session: defaultSessionReducer,
    dialog: dialogReducer,
  };

  return combineReducers(Object.assign({}, defaultReducers, overwritingReducers));
}

export default createRecuder;
