import { History, LocationDescriptor } from "history";
import { useHistory as useRouterHistory } from "react-router-dom";
import { PageType } from "../component";

function historyFunctions(history: History) {
  function push<T>(page: PageType<T>, parameters: URLSearchParams) {
    history.push(toLocation(page, parameters));
  }

  function replace<T>(page: PageType<T>, parameters: URLSearchParams) {
    history.replace(toLocation(page, parameters));
  }

  return { push, replace };
}

function toLocation<T>(page: PageType<T>, parameters: URLSearchParams): LocationDescriptor {
  const location: LocationDescriptor = {
    pathname: page.PATH,
    state: page.STATE,
    search: parameters.toString(),
  };

  return location;
}

export function useHistory() {
  const history = useRouterHistory();
  return historyFunctions(history);
}
