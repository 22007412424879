import URL from "./URL";

export enum Method {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

class Request {
  #url: URL;
  #method: Method;

  constructor(url: URL, method: Method = Method.POST) {
    this.#url = url;
    this.#method = method;
  }

  get method(): Method {
    return this.#method;
  }

  get url(): string {
    return this.#url.toString;
  }
}

export default Request;
