import React from "react";
import { useLocation, useParams } from "react-router-dom";

export type PageType<T> = {
  PATH: string;
  NAME: string;
  STATE: T | undefined;
};

type LocationProps<T> = {
  props: T;
};

export function withPage<T>(PageComponent: React.ComponentType<T>) {
  return function Page(props: any) {
    const location = useLocation<LocationProps<T>>();
    const params = useParams();
    return <PageComponent {...props} {...location.state} search={location.search} params={params} />;
  };
}

export function usePageParameters() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}
