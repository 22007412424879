export const HEADER = {
  LANGUAGE: "LOCALE",
  SESSIONID: "SessionId",
};

interface IHeaders {
  [key: string]: string | number;
}

export default class HeaderOptions {
  #headers: IHeaders;
  constructor() {
    this.#headers = {};
  }

  setHeader(header: string, value: string | number): void {
    this.#headers[header] = value;
  }

  get config(): { headers: IHeaders } {
    return {
      headers: this.#headers,
    };
  }
}
